import React from "react";
import { SingleHero, SingleHeader } from "@modules/singles/index";
import { SEO, VideoEmbed } from "@common";

import { RichText, Section, Container } from "@ui";

/* eslint react/prop-types: 0 */

const VideoSingle = ({ data }) => {
  const { uid, title, date, description, youtubeVideoId, categories } = data;

  return (
    <>
      <SEO title={title} description={description.text} />

      <SingleHero />

      <Section size="sm">
        <Container size="cb" className="relative">
          <SingleHeader
            uid={uid}
            backUrl="/videos/"
            backText="all videos"
            title={title}
            date={date}
            categories={categories}
          />
        </Container>
      </Section>

      <Section hasPaddingTop={false}>
        <Container size="cb">
          <RichText
            html={description.html}
            className="RichText--content-builder mb-8"
          />

          <VideoEmbed youtubeVideoId={youtubeVideoId} title={title} />
        </Container>
      </Section>
    </>
  );
};

export default VideoSingle;
