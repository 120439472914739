import React from "react";
import { graphql } from "gatsby";
import { keysToCamel } from "@utils";
import VideoSingle from "../../pageTemplates/VideoSingle";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveImageData from "../dataResolvers/resolveImageData";

/* eslint react/prop-types: 0 */

const dataResolver = ({ prismicVideos }) => {
  const { uid, data } = prismicVideos;
  const { thumbnail, categories, display_date, ...rest } = data;

  const resolved = {
    data: {
      uid,
      date: display_date,
      categories: categories.map(({ category }) => ({
        title: category.toLowerCase(),
        id: category
          .toLowerCase()
          .split(" ")
          .join("-"),
      })),
      heroImage: resolveImageData(thumbnail[0]),
      ...rest,
    },
  };

  return keysToCamel(resolved);
};

const VideoSingleQuery = ({ data: _data, location }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <VideoSingle {...data} location={location} />;
};

export default VideoSingleQuery;

export const query = graphql`
  query($uid: String!) {
    prismicVideos(uid: { eq: $uid }) {
      uid
      data {
        title
        display_date(formatString: "MMM DD, YYYY")
        youtube_video_id
        description {
          text
          html
        }
        thumbnail {
          focal_point_x
          focal_point_y
          image {
            url
            alt
            dimensions {
              height
              width
            }
          }
        }
        categories {
          category
        }
      }
    }
  }
`;
